import './App.scss';
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { initLight } from './light'

function App() {
  const gui = new GUI()
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
  }

    // Canvas
  const canvas = document.querySelector('canvas.webgl')

  // Scene
  const scene = new THREE.Scene()

  // Ambient light
  initLight(gui, scene)

  window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  })

  // Base camera
  const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 10000)
  camera.position.x = 5
  camera.position.y = 5
  camera.position.z = 5

  scene.add(camera)
  const material = new THREE.MeshStandardMaterial()
  const sphere = new THREE.Mesh(
    new THREE.SphereGeometry(0.5, 32, 32),
    material
  )
  sphere.castShadow = true
  sphere.position.set(1, 1, 0)
  scene.add(sphere)

  const onProgress = function ( xhr ) {
    console.log(xhr)
    if ( xhr.lengthComputable ) {
        const percentComplete = xhr.loaded / xhr.total * 100;
        console.log( percentComplete.toFixed( 2 ) + '% downloaded' );
    }
};

  new MTLLoader()
    .setPath( 'models/controller/' )
    .load( 'controller_wireless_1024.mtl', function ( materials ) {

        materials.preload();
        
        new OBJLoader()
        .setMaterials( materials )
        .setPath( 'models/controller/' )
        .load( 'controller_wireless_1024.obj', function ( object ) {
          
                object.position.set(10, 10, 0)
                //object.scale.setScalar( 10 );
                scene.add( object );

            }, onProgress );

    } );

  // Controls
  const controls = new OrbitControls(camera, canvas)
  controls.enableDamping = true
  controls.maxPolarAngle = Math.PI * 0.495;
  controls.minDistance = 5.0;
  controls.maxDistance = 500.0;

  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
  })

  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.5;
  camera.lookAt(new THREE.Vector3(0, 0, 0))

  const clock = new THREE.Clock()

  const tick = () =>
  {
      const elapsedTime = clock.getElapsedTime()

      // Update controls
      controls.update()
      // Render
      renderer.render(scene, camera)
      // Call tick again on the next frame
      window.requestAnimationFrame(tick)
  }

  tick()


  return (
    <div className="hub">
      <h1 className='title'>HFB</h1>
      <h1 className='title'>GAMES</h1>
    </div>
  );
}

export default App;
